import api, { transformResponse } from "services/api";

export const homeApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchConsumerBackendConstants: build.query({
      query: () => "/api/v1/consumer/supported-constants/",
      transformResponse: (response) => transformResponse(response),
    }),
    fetchConsumerClientSummary: build.query({
      query: () => "/api/v1/consumer/users/me/client/summary/",
      transformResponse: (response) => transformResponse(response),
    }),
  }),
  overrideExisting: false,
});

export const { useFetchConsumerBackendConstantsQuery, useFetchConsumerClientSummaryQuery } =
  homeApi;
