import api, { transformRequest, transformResponse } from "services/api";

export const authApi = api.enhanceEndpoints({ addTagTypes: ["Me"] }).injectEndpoints({
  endpoints: (build) => ({
    changePassword: build.mutation({
      query: (payload) => ({
        url: "/api/v1/common/users/me/change-password/",
        method: "POST",
        body: transformRequest(payload),
      }),
      transformResponse: (response) => transformResponse(response),
    }),
    creditorUserSignUp: build.mutation({
      query: (payload) => ({
        url: "/api/v1/creditor/user/register/",
        method: "POST",
        body: transformRequest(payload),
      }),
      transformResponse: (response) => transformResponse(response),
    }),
    fetchMe: build.query({
      query: () => `/api/v1/common/users/me/`,
      transformResponse: (response) => transformResponse(response),
      providesTags: [{ type: "Me" }],
    }),
    login: build.mutation({
      query: (payload) => ({
        url: "/api/v1/token/",
        method: "POST",
        body: transformRequest(payload),
      }),
    }),
    refreshToken: build.mutation({
      query: (payload) => ({
        url: "/api/v1/token/refresh/",
        method: "POST",
        body: transformRequest(payload),
      }),
    }),
    consumerUserSignUp: build.mutation({
      query: (payload) => ({
        url: "/api/v1/consumer/auth/register/",
        method: "POST",
        body: transformRequest(payload),
      }),
      transformResponse: (response) => transformResponse(response),
    }),
    consumerAccountLookup: build.mutation({
      query: (payload) => ({
        url: `/api/v1/consumer/auth/lookup/`,
        method: "POST",
        body: transformRequest(payload),
      }),
      transformResponse: (response) => transformResponse(response),
    }),
  }),
  overrideExisting: false,
});

export const {
  useChangePasswordMutation,
  useFetchMeQuery,
  useLazyFetchMeQuery,
  useLoginMutation,
  useRefreshTokenMutation,
  useCreditorUserSignUpMutation,
  useConsumerAccountLookupMutation,
  useConsumerUserSignUpMutation,
} = authApi;
