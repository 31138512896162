import { PlusOutlined } from "@ant-design/icons";
import { Button, Empty, Flex } from "antd";
import AddPaymentMethodModal from "features/consumerPortalPaymentMethods/components/addPaymentMethodModal";
import PaymentMethodCard from "features/consumerPortalPaymentMethods/components/paymentMethodCard";
import { useGetPaymentMethodsQuery } from "features/consumerPortalPaymentMethods/paymentMethodsAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  background-color: #f5f5f5;
  border-color: #4096ff;
  color: #4096ff;
  height: 180px;
  width: 350px;
  margin-top: 1px;
`;
const StyledHeader = styled.h3`
  margin-bottom: 16px;
`;

export default function PaymentMethodsPage() {
  const { data: paymentMethods, isLoading } = useGetPaymentMethodsQuery();
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <StyledHeader>Payment Methods</StyledHeader>
      {!paymentMethods?.length && (
        <Empty
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100% - 20px - 10em)",
          }}
          description={
            <Flex vertical justify="center" align="center">
              <p style={{ color: "gray", marginTop: 0 }}>No payment methods found.</p>
              <Button type="primary" onClick={() => setModalVisible(true)} icon={<PlusOutlined />}>
                Add Payment Method
              </Button>
            </Flex>
          }
        />
      )}
      <Flex vertical={false} wrap="wrap" gap="small">
        {paymentMethods?.map((paymentMethod) => (
          <PaymentMethodCard hasDelete paymentMethod={paymentMethod} />
        ))}
        {paymentMethods?.length > 0 && (
          <StyledButton type="dashed" onClick={() => setModalVisible(true)} icon={<PlusOutlined />}>
            Add Payment Method
          </StyledButton>
        )}
      </Flex>
      {isModalVisible && (
        <AddPaymentMethodModal
          onCancel={() => setModalVisible(false)}
          onOk={() => setModalVisible(false)}
          open={isModalVisible}
        />
      )}
    </>
  );
}
