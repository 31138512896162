import FullPayment from "features/consumerPortalPaymentPage/components/fullPayment";
import PartialPayment from "features/consumerPortalPaymentPage/components/partialPayment";
import PaymentConfirmation from "features/consumerPortalPaymentPage/components/paymentConfirmation";
import PaymentPlan from "features/consumerPortalPaymentPage/components/paymentPlan";
import SelectAccounts from "features/consumerPortalPaymentPage/components/selectAccounts";
import SelectPaymentOption from "features/consumerPortalPaymentPage/components/selectPaymentOption";
import {
  PAYMENT_VIEW_SCREEN_ENUMS,
  selectPaymentsSlice,
} from "features/consumerPortalPaymentPage/paymentsSlice";
import { useSelector } from "react-redux";

function ConsumerPortalPaymentPage() {
  const paymentsSlice = useSelector(selectPaymentsSlice);

  return (
    <>
      {paymentsSlice.currentView === PAYMENT_VIEW_SCREEN_ENUMS.SelectAccountsForPayment && (
        <SelectAccounts />
      )}
      {paymentsSlice.currentView === PAYMENT_VIEW_SCREEN_ENUMS.SelectPaymentOption && (
        <SelectPaymentOption />
      )}
      {paymentsSlice.currentView === PAYMENT_VIEW_SCREEN_ENUMS.SetupFullPayment && <FullPayment />}
      {paymentsSlice.currentView === PAYMENT_VIEW_SCREEN_ENUMS.SetupPartialPayment && (
        <PartialPayment />
      )}
      {paymentsSlice.currentView === PAYMENT_VIEW_SCREEN_ENUMS.SetupPaymentPlanConfiguration && (
        <PaymentPlan />
      )}
      {paymentsSlice.currentView === PAYMENT_VIEW_SCREEN_ENUMS.PaymentConfirmation && (
        <PaymentConfirmation />
      )}
    </>
  );
}

export default ConsumerPortalPaymentPage;
