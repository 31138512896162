import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from "antd";
import { DATE_FORMAT } from "common/constants";
import { formatCurrency } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import currency from "currency.js";
import { useCreatePaymentIntentMutation } from "features/consumerPortalPaymentHistory/paymentIntentsAPI";
import AddPaymentMethodForm from "features/consumerPortalPaymentMethods/components/addPaymentMethodForm";
import AddPaymentMethodModal from "features/consumerPortalPaymentMethods/components/addPaymentMethodModal";
import { useGetPaymentMethodsQuery } from "features/consumerPortalPaymentMethods/paymentMethodsAPI";
import {
  PAYMENT_VIEW_SCREEN_ENUMS,
  selectPaymentsSlice,
  setCurrentView,
  setRecurringAmountChange,
} from "features/consumerPortalPaymentPage/paymentsSlice";
import { getNextDate } from "features/payments/paymentDefaults";
import moment from "moment-timezone";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const StyledCard = styled(Card)`
  margin-bottom: 12px;
  .ant-card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-card-head {
    border-bottom: none;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 0;
`;

const StyledDiv = styled(Flex)`
  margin-top: 12px;
  flex-direction: row-reverse;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 150px;
`;

const StyledValue = styled.span`
  overflow-wrap: anywhere;
`;

const StyledLabel = styled.span`
  text-transform: capitalize;
  color: grey;
  font-size: 13px;
  flex: 1;
`;

const StyledPayButton = styled(Button)`
  height: 48px;
`;

function PaymentPlan() {
  const [form] = Form.useForm();
  const [processPayment, { isLoading: isPosting }] = useCreatePaymentIntentMutation();
  const dispatch = useDispatch();
  const paymentsSlice = useSelector(selectPaymentsSlice);
  const { data: paymentMethods, isLoading } = useGetPaymentMethodsQuery();
  const [isModalVisible, setModalVisible] = useState(false);

  const onFinish = async ({
    startDate,
    paymentMethodUuid,
    newPaymentMethod,
    recurringAmount,
    frequency,
    isNewPaymentMethod,
  }) => {
    const { totalPaymentAmount } = paymentsSlice;
    const numberOfPayments = Math.floor(
      currency(totalPaymentAmount, { precision: 2 }).divide(recurringAmount).value,
    );
    const balloonPayment = currency(totalPaymentAmount, { precision: 2 }).subtract(
      currency(recurringAmount, { precision: 2 }).multiply(numberOfPayments),
    ).value;

    let currentDate = moment(startDate, DATE_FORMAT);

    const intents = Array.from({ length: numberOfPayments }, (_, index) => {
      const intent = {
        totalAmount:
          index === numberOfPayments - 1
            ? currency(recurringAmount, { precision: 2 }).add(balloonPayment).value
            : recurringAmount,
        scheduledDate: currentDate.format(DATE_FORMAT),
        paymentMethodUuid,
        accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
        paymentIntentType: "full",
        isPaymentPlan: true,
      };
      currentDate = getNextDate({ currentDate, frequency });
      return intent;
    });
    const newPaymentMethodFields = {};
    if (isNewPaymentMethod) {
      const { paymentMethod, ...newPaymentMethodValues } = newPaymentMethod;
      const [firstName, lastName] = (newPaymentMethodValues.name ?? "").split(" ");
      newPaymentMethodValues.firstName = firstName;
      newPaymentMethodValues.lastName = lastName;

      if (paymentMethod === "creditCard") {
        // post-transformation for credit card
        newPaymentMethodValues.cardExpMonth = moment(
          newPaymentMethodValues.cardExpDate,
          "MM/YY",
        ).format("MM");
        newPaymentMethodValues.cardExpYear = moment(
          newPaymentMethodValues.cardExpDate,
          "MM/YY",
        ).format("YYYY");
        newPaymentMethodFields.newCreditCard = newPaymentMethodValues;
      }
      if (paymentMethod === "bankAccount") {
        newPaymentMethodFields.newCreditCard = newPaymentMethodValues;
      }
    }
    const result = await processPayment({
      intents,
      ...newPaymentMethodFields,
      newPaymentMethod,
      paymentIntentType: "full",
      executeIfReady: true,
    });
    if ("data" in result) {
      dispatch(setCurrentView(PAYMENT_VIEW_SCREEN_ENUMS.PaymentConfirmation));
    }
    if ("error" in result) {
      message.error("Failed to process payment");
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current >= moment().add(30, "day") && current < moment().startOf("day");
  };

  const initialValues = {
    recurringAmount: paymentsSlice.totalPaymentAmount,
    frequency: paymentsSlice.paymentFrequency,
    startDate: paymentsSlice.startDate,
    isNewPaymentMethod: false,
    newPaymentMethod: {
      // paymentMethod: "creditCard",
    },
  };

  const handleNumberOfPayments = (amountToDistribute, recurringAmount) => {
    amountToDistribute = currency(amountToDistribute, { precision: 4 });
    const numberOfPayments = Math.floor(amountToDistribute.divide(recurringAmount).value);
    return numberOfPayments;
  };

  const handleBalloonPayment = (amountToDistribute, numberOfPayments, recurringAmount) => {
    amountToDistribute = currency(amountToDistribute, { precision: 4 });
    numberOfPayments = currency(numberOfPayments, { precision: 4 });
    recurringAmount = currency(recurringAmount, { precision: 4 });
    const balloonPaymentAmount = amountToDistribute.subtract(
      numberOfPayments.multiply(recurringAmount),
    );
    return balloonPaymentAmount.value;
  };

  const onChangeRecurringAmount = (newRecurringAmount) => {
    const { downPayment } = paymentsSlice;
    let isIncludedWithLastPayment = form.getFieldValue("isIncludedWithLastPayment");
    const amountToDistribute = currency(paymentsSlice.totalPaymentAmount, {
      precision: 4,
    }).subtract(downPayment).value;
    const numberOfPayments = handleNumberOfPayments(amountToDistribute, newRecurringAmount);
    const balloonPayment = handleBalloonPayment(
      amountToDistribute,
      numberOfPayments,
      newRecurringAmount,
    );
    if (newRecurringAmount !== null) {
      form.setFieldsValue({ numberOfPayments, balloonPayment });
      if (balloonPayment === 0) {
        form.setFieldsValue({ isIncludedWithLastPayment: true });
        isIncludedWithLastPayment = true;
      }
    }
    dispatch(
      setRecurringAmountChange({
        recurringAmount: newRecurringAmount,
        numberOfPayments,
        balloonPayment,
        isIncludedWithLastPayment,
      }),
    );
  };

  const onPrevious = () => {
    dispatch(setCurrentView(PAYMENT_VIEW_SCREEN_ENUMS.SelectPaymentOption));
  };

  return (
    <>
      <StyledButton icon={<ArrowLeftOutlined />} type="link" onClick={onPrevious}>
        Previous: Choose Payment Option
      </StyledButton>
      <Form form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues}>
        <Row gutter={16}>
          <Col span={12}>
            <StyledCard title="Set up Payment Plan">
              <Form.Item
                label="Recurring Amount"
                name="recurringAmount"
                rules={[{ required: true, message: "Please enter the payment amount" }]}
              >
                <StyledInputNumber
                  onChange={onChangeRecurringAmount}
                  placeholder="Enter Recurring Amount"
                  prefix="$"
                  precision={2}
                  controls={false}
                  min={0}
                />
              </Form.Item>
              <Form.Item
                label="Frequency"
                name="frequency"
                rules={[{ required: true, message: "Please select the frequency" }]}
              >
                <Select
                  placeholder="Select Frequency"
                  options={[
                    { label: "Monthly", value: "monthly" },
                    { label: "Bi-monthly", value: "bimonthly" },
                    { label: "Weekly", value: "weekly" },
                    { label: "Bi-weekly", value: "biweekly" },
                  ]}
                  popupMatchSelectWidth={false}
                />
              </Form.Item>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Please select the payment date" }]}
              >
                <AktDatePicker type="date" disabledDate={disabledDate} />
              </Form.Item>
            </StyledCard>
            <StyledCard title="Contact">
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "Please enter your email" }]}
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
            </StyledCard>
            <StyledCard title="Payment Information">
              <AddPaymentMethodForm prefix="newPaymentMethod" />
            </StyledCard>
          </Col>
          <Col span={12}>
            <Card title="Payment Summary">
              <Row align="middle">
                <StyledLabel>Number of Payments:</StyledLabel>
                <StyledValue>{paymentsSlice.numberOfPayments}</StyledValue>
              </Row>
              <Row align="middle">
                <StyledLabel>Payment Frequency:</StyledLabel>
                <StyledValue>
                  {paymentsSlice.paymentFrequency.charAt(0).toUpperCase() +
                    paymentsSlice.paymentFrequency.slice(1)}
                </StyledValue>
              </Row>
              <Row align="middle">
                <StyledLabel>Recurring Amount:</StyledLabel>
                <StyledValue>{formatCurrency(paymentsSlice.recurringAmount)}</StyledValue>
              </Row>
              {paymentsSlice.paymentsSchedule?.map((paymentIntent) => {
                return (
                  <>
                    <Row align="middle">
                      <StyledLabel>Payment Amount:</StyledLabel>
                      <StyledValue>{formatCurrency(paymentIntent.amount)}</StyledValue>
                    </Row>
                    <Row align="middle">
                      <StyledLabel>Payment Start Date:</StyledLabel>
                      <StyledValue>{paymentIntent.date}</StyledValue>
                    </Row>
                  </>
                );
              })}
              <Divider />
              {paymentsSlice.selectedAccounts?.map((selectedAccount) => {
                return (
                  <>
                    <Row align="middle">
                      <StyledLabel>Current Creditor</StyledLabel>
                      <StyledValue>{selectedAccount.currentCreditorName}</StyledValue>
                    </Row>
                    <Row align="middle">
                      <StyledLabel>Account Number</StyledLabel>
                      <StyledValue>{selectedAccount.externalId}</StyledValue>
                    </Row>
                    <Row align="middle">
                      <StyledLabel>Current Balance</StyledLabel>
                      <StyledValue>{formatCurrency(selectedAccount.totalBalance)}</StyledValue>
                    </Row>
                  </>
                );
              })}
              <Divider />
              <Row align="middle">
                <StyledLabel>Current Balance</StyledLabel>
                <StyledValue>{formatCurrency(paymentsSlice.totalPaymentAmount)}</StyledValue>
              </Row>
              <Row align="middle">
                <StyledLabel>Payment Amount</StyledLabel>
                <StyledValue>{formatCurrency(paymentsSlice.totalPaymentAmount)}</StyledValue>
              </Row>
            </Card>
            <StyledDiv>
              <StyledPayButton
                block
                size="large"
                type="primary"
                htmlType="submit"
                loading={isPosting}
              >
                Set up Payment Plan
              </StyledPayButton>
            </StyledDiv>
          </Col>
        </Row>
      </Form>

      {isModalVisible && (
        <AddPaymentMethodModal
          onCancel={() => setModalVisible(false)}
          onOk={() => {
            setModalVisible(false);
            form.setFieldsValue({ isNewPaymentMethod: false });
          }}
          open={isModalVisible}
        />
      )}
    </>
  );
}

export default PaymentPlan;
