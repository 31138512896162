import api, { transformMultipart, transformRequest, transformResponse } from "services/api";

export const documentsApi = api
  .enhanceEndpoints({ addTagTypes: ["ConsumerPortalDocuments"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getConsumerPortalDebtorDocuments: build.query({
        query: () => `/api/v1/consumer/documents/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ uuid }) => ({ type: "ConsumerPortalDocuments", uuid })),
                { type: "ConsumerPortalDocuments", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'ConsumerPortalDocuments', id: 'LIST' }` is invalidated
              [{ type: "ConsumerPortalDocuments", id: "LIST" }],
      }),
      deleteConsumerPortalDebtorDocument: build.mutation({
        query: (payload) => ({
          url: `/api/v1/consumer/documents/${payload.uuid}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "ConsumerPortalDocuments", id: "LIST" }],
      }),
      postConsumerPortalDebtorDocuments: build.mutation({
        query: ({ file, ...values }) => ({
          url: `/api/v1/consumer/documents/create/`,
          method: "POST",
          body: transformMultipart(values, file),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Documents-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "ConsumerPortalDocuments", id: "LIST" }],
      }),
    }),
  });

export const {
  useDeleteConsumerPortalDebtorDocumentMutation,
  useGetConsumerPortalDebtorDocumentsQuery,
  useLazyGetConsumerPortalDebtorDocumentsQuery,
  usePostConsumerPortalDebtorDocumentsMutation,
} = documentsApi;
