import { LockOutlined, MailOutlined, SolutionOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Select, Steps, message } from "antd";
import { resetStateAction } from "common/redux/resetStateAction";
import { AktDatePicker } from "components/aktDatePicker";
import {
  useConsumerAccountLookupMutation,
  useConsumerUserSignUpMutation,
} from "features/auth/authAPI";
import { selectIsAccessTokenStoredInRtk } from "features/auth/authSlice";
import { useWhiteLabel } from "providers/whiteLabelProvider";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)``;

const StyledDateItem = styled(Form.Item)`
  .ant-picker {
    width: 100%;
  }
`;

const StyledHeading = styled.h2`
  margin-top: 0;
  text-align: center;
`;

const StyledCol = styled(Col)`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 36px 36px 24px 36px;
  background: #fff;
  border-radius: 10px;
  min-width: 500px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 110px);
  background: #f7f7f7;
  padding-bottom: 24px;
  padding-top: 24px;
`;

const StyledOutsideLink = styled.div`
  margin-top: 28px;
  text-align: center;
  & a {
    color: #0098ed;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    font-size: 14px;
  }
`;

function ConsumerSignUpForm() {
  const { isWhiteLabeled, slug, signupProps } = useWhiteLabel();
  const navigate = useNavigate();

  const [validatedData, setValidatedData] = useState({});
  const [signUp, { isLoading: isSignupLoading }] = useConsumerUserSignUpMutation();
  const [lookupForm] = Form.useForm();
  const [mainForm] = Form.useForm();
  const isAccessTokenStoredInRtk = useSelector(selectIsAccessTokenStoredInRtk);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [lookup, { isLoading: isLookupLoading }] = useConsumerAccountLookupMutation();

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onVerifyClicked = async () => {
    let values = await lookupForm.validateFields();
    if (signupProps?.preTransform) {
      values = signupProps.preTransform(values);
    }
    const payload = {
      ...values,
      agencySlug: slug,
    };
    const result = await lookup(payload);
    if ("data" in result) {
      setValidatedData(payload);
      next();
    }
  };

  const onSignupClicked = async () => {
    let values = await mainForm.validateFields();
    if (signupProps?.preTransform) {
      values = signupProps.preTransform(values);
    }
    const result = await signUp({
      ...validatedData,
      ...values,
      agencySlug: slug,
    });
    if ("data" in result) {
      message.success("You have signed up successfully!");
      navigate("/login");
    }
  };

  // Reset state when user navigates to signup page
  useEffect(() => {
    if (isAccessTokenStoredInRtk) {
      dispatch(resetStateAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signupSteps = [
    {
      title: "Verification",
      icon: <SolutionOutlined />,
      content: (
        <Form
          form={lookupForm}
          initialValues={{
            debtType: "consumer",
            verificationMethod: undefined,
          }}
        >
          <Form.Item
            name="debtType"
            hasFeedback
            rules={[{ required: true, message: "Please select debt type" }]}
          >
            <Select placeholder="Choose Debt Type" data-testid="debtType">
              <Select.Option value="consumer">Consumer</Select.Option>
              <Select.Option value="commercial">Commercial</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(previous, curr) => previous?.debtType !== curr?.debtType}
          >
            {() => {
              const debtType = lookupForm.getFieldValue("debtType");
              if (debtType === "consumer") {
                return (
                  <>
                    <Form.Item
                      name="externalAccountId"
                      hasFeedback
                      rules={[{ required: true, message: "Please enter the account number" }]}
                    >
                      <Input placeholder="Account Number" data-testid="externalAccountId" />
                    </Form.Item>
                    <Form.Item
                      name="firstName"
                      hasFeedback
                      rules={[{ required: true, message: "Please enter your first name" }]}
                    >
                      <Input placeholder="First Name" data-testid="firstName" />
                    </Form.Item>
                    <Form.Item
                      name="lastName"
                      hasFeedback
                      rules={[{ required: true, message: "Please enter your last name" }]}
                    >
                      <Input placeholder="Last Name" data-testid="lastName" />
                    </Form.Item>
                    <Form.Item
                      name="verificationMethod"
                      hasFeedback
                      rules={[{ required: true, message: "Please select a verification method" }]}
                    >
                      <Select placeholder="Verification Method">
                        <Select.Option value="dob">Date of Birth</Select.Option>
                        <Select.Option value="ssn">SSN</Select.Option>
                        <Select.Option value="phone">Phone</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(previous, curr) =>
                        previous?.verificationMethod !== curr?.verificationMethod
                      }
                    >
                      {() => {
                        const verificationMethod = lookupForm.getFieldValue("verificationMethod");
                        if (verificationMethod === "dob") {
                          return (
                            <StyledDateItem
                              name="dob"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your date of birth",
                                },
                              ]}
                            >
                              <AktDatePicker placeholder="MM/DD/YYYY" data-testid="dob" />
                            </StyledDateItem>
                          );
                        }

                        if (verificationMethod === "ssn") {
                          return (
                            <>
                              {/*
                                Usually Consumers look up their accounts by the last 4 digits of SSN, not the
                                full SSN, as giving the full thing is more risky.
                              */}
                              <Form.Item
                                hasFeedback
                                name="ssnLast4"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter the last 4 digits of your SSN",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Last 4 Digits of SSN"
                                  data-testid="ssnLast4"
                                  maxLength={4}
                                />
                              </Form.Item>
                            </>
                          );
                        }

                        if (verificationMethod === "phone") {
                          // Verification method is phone
                          return (
                            <Form.Item
                              name="phoneNumber"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your phone number",
                                },
                              ]}
                            >
                              <Input placeholder="XXX-XXX-XXXX" data-testid="phoneNumber" />
                            </Form.Item>
                          );
                        }
                        return null;
                      }}
                    </Form.Item>
                  </>
                );
              }

              return (
                <>
                  <Form.Item
                    name="externalAccountId"
                    hasFeedback
                    rules={[{ required: true, message: "Please enter the account number" }]}
                  >
                    <Input placeholder="Account Number" data-testid="debtorAccountNumber" />
                  </Form.Item>
                  <Form.Item
                    name="companyName"
                    hasFeedback
                    rules={[{ required: true, message: "Please enter the company name" }]}
                  >
                    <Input placeholder="Company Name" data-testid="companyName" />
                  </Form.Item>
                  <Form.Item
                    name="companyEin"
                    hasFeedback
                    rules={[{ required: true, message: "Please enter the company EIN" }]}
                  >
                    <Input placeholder="Company EIN" data-testid="companyEin" />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Sign Up",
      content: (
        <>
          <Form.Item
            name="email"
            hasFeedback
            rules={[
              { type: "email", message: "Please enter valid email" },
              {
                required: true,
                message: "Please enter email",
              },
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder="Email" data-testid="email" />
          </Form.Item>
          <Form.Item
            hasFeedback
            name="password"
            rules={[{ required: true, message: "Please enter your password" }]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Enter password" />
          </Form.Item>
          <Form.Item
            hasFeedback
            name="confirmPassword"
            rules={[
              { required: true, message: "Please confirm your password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords don't match"));
                },
              }),
            ]}
            dependencies={["password"]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Confirm password" />
          </Form.Item>
        </>
      ),
      wrapperProps: {
        style: {
          maxWidth: "100%",
          width: "100%",
        },
      },
    },
  ];

  if (!isWhiteLabeled) {
    return <Navigate to="/404" />;
  }

  return (
    <StyledContainer>
      <StyledCol>
        <StyledHeading>Sign Up</StyledHeading>
        <StyledForm
          initialValues={{ debtType: "consumer" }}
          autoComplete="off"
          scrollToFirstError
          form={mainForm}
          validateMessages={{ required: "This is a required field" }}
          className="sign-up-form"
        >
          <Steps
            current={current}
            style={{ marginBottom: "24px" }}
            size="small"
            direction="horizontal"
            progressDot
          >
            {signupSteps.map((step) => (
              <Steps.Step key={step.title} title={step.title} icon={step.icon} />
            ))}
          </Steps>
          <div>{signupSteps[current].content}</div>
          <div
            style={{
              marginTop: 24,
              textAlign: "center",
            }}
          >
            {current < signupSteps.length - 1 && (
              <Button
                type="primary"
                block
                size="large"
                onClick={onVerifyClicked}
                loading={isLookupLoading}
              >
                Verify
              </Button>
            )}
            {current > 0 && (
              <Button
                style={{
                  margin: "0 8px",
                  color: "#000",
                }}
                onClick={() => prev()}
                type="link"
              >
                Back
              </Button>
            )}
            {current === signupSteps.length - 1 && (
              <Button type="primary" onClick={onSignupClicked} loading={isSignupLoading}>
                Sign Up
              </Button>
            )}
          </div>
        </StyledForm>
        <StyledOutsideLink>
          <a href="/login">Already have an account? Log in</a>
        </StyledOutsideLink>
      </StyledCol>
    </StyledContainer>
  );
}

export default ConsumerSignUpForm;
