import ForgotPasswordForm from "features/auth/components/consumerForgotPasswordForm";
import LoginPageLayout from "layouts/consumerPortal/loginPageLayout";

function ForgotPassword() {
  return (
    <LoginPageLayout>
      <ForgotPasswordForm />
    </LoginPageLayout>
  );
}

export default ForgotPassword;
