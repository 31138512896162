import moment from "moment-timezone";
import { Button, Card } from "antd";
import { DATE_FORMAT } from "common/constants";
import currency from "currency.js";
import { useGetConsumerPortalAccountsQuery } from "features/consumerPortalDashboard/accountsAPI";
import PaymentScheduleCard from "features/consumerPortalPaymentPage/components/paymentScheduleCard";
import {
  PAYMENT_VIEW_SCREEN_ENUMS,
  selectPaymentsSlice,
  setCurrentView,
  setOneTimePaymentsSchedule,
  setPaymentPlanConfiguration,
  setupAsPaymentPlan,
} from "features/consumerPortalPaymentPage/paymentsSlice";
import { getPaymentsScheduleForOneTimePayment } from "features/payments/paymentDefaults";
import Content from "layouts/consumerPortal/content";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";

const StyledDiv = styled.div`
  margin-top: 24px;
  margin-left: 16px;
  & span {
    margin-left: 12px;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 24px;
  float: right;
`;

function SelectPaymentOption() {
  const dispatch = useDispatch();
  const paymentsSlice = useSelector(selectPaymentsSlice);
  const { data: accounts, isLoading } = useGetConsumerPortalAccountsQuery();

  const debtTotal = Object.values(paymentsSlice.selectedAccounts).reduce(
    (accumulator, selectedAccount) => {
      const account = accounts?.find(
        (debtorAccount) => debtorAccount.uuid === selectedAccount.uuid,
      );
      return currency(accumulator, { precision: 4 }).add(account?.totalBalance ?? 0);
    },
    0,
  );

  const onPrevious = () => {
    dispatch(setCurrentView(PAYMENT_VIEW_SCREEN_ENUMS.SelectAccountsForPayment));
  };

  const onSelect = (paymentMethod) => {
    const action = {
      full: PAYMENT_VIEW_SCREEN_ENUMS.SetupFullPayment,
      partial: PAYMENT_VIEW_SCREEN_ENUMS.SetupPartialPayment,
      plan: PAYMENT_VIEW_SCREEN_ENUMS.SetupPaymentPlanConfiguration,
    }[paymentMethod];

    const originalTotalAmount = currency(debtTotal).value;
    if (paymentMethod === "full") {
      // TODO: We need to consider interest IF the user selects a different date.
      // Interest can accrue if the full payment is set to future dates.
      // Since this is the first click, we don't have to worry about it on this page.
      const paymentsSchedule = getPaymentsScheduleForOneTimePayment({
        accountIds: paymentsSlice.selectedAccounts,
        paymentAmount: originalTotalAmount,
      });
      dispatch(
        setOneTimePaymentsSchedule({
          paymentIntentType: "full",
          originalTotalAmount,
          totalPaymentAmount: originalTotalAmount,
          paymentsSchedule,
        }),
      );
    } else if (paymentMethod === "partial") {
      const paymentsSchedule = getPaymentsScheduleForOneTimePayment({
        accountIds: paymentsSlice.selectedAccounts,
        paymentAmount: originalTotalAmount,
      });
      dispatch(
        setOneTimePaymentsSchedule({
          paymentIntentType: "partial",
          partialPaymentAmount: 0, // Initially start the partial payment at 0.
          originalTotalAmount,
          totalPaymentAmount: originalTotalAmount,
          paymentsSchedule,
        }),
      );
    } else if (paymentMethod === "plan") {
      // Reset back to initial payment plan states
      dispatch(
        setupAsPaymentPlan({
          originalTotalAmount,
        }),
      );

      // NOTE: For now, we default payment plans to a single payment. This may change in the future.
      const paymentsSchedule = getPaymentsScheduleForOneTimePayment({
        accountIds: paymentsSlice.selectedAccounts,
        paymentAmount: originalTotalAmount,
      });
      dispatch(
        setPaymentPlanConfiguration({
          downPayment: 0,
          recurringAmount: originalTotalAmount,
          numberOfPayments: 1,
          startDate: moment().format(DATE_FORMAT),
          frequency: "monthly",
          paymentsSchedule,
        }),
      );
    }

    dispatch(setCurrentView(action));
  };

  return (
    <Content>
      <h3>Select Your Payment Option</h3>
      <Card>
        <PaymentScheduleCard type="full" onSelect={onSelect} />
        <PaymentScheduleCard type="partial" onSelect={onSelect} />
        <PaymentScheduleCard type="plan" onSelect={onSelect} />
        <StyledDiv>
          <div style={{ fontStyle: "italic", textAlign: "center" }}>
            By authorizing this transaction, you understand and agree that all current and postdated
            payments will be applied to the balance of the debt. You also understand that making a
            payment may not prevent the remaining debt from being charged-off in accordance to the
            terms of the contract executed between you and the creditor. In the event of a
            charge-off, all payment arrangements may be voided. Visit{" "}
            <Link to="/contact">Contact Us</Link> if you have any questions.
          </div>
        </StyledDiv>
      </Card>
      <StyledButton icon={<ArrowLeftOutlined />} onClick={onPrevious}>
        Previous: Select Payment Accounts
      </StyledButton>
    </Content>
  );
}

export default SelectPaymentOption;
