import api, { transformRequest, transformResponse } from "services/api";

export const paymentsApi = api
  .enhanceEndpoints({ addTagTypes: ["ConsumerPortalPaymentIntents"] })
  .injectEndpoints({
    endpoints: (build) => ({
      calculateBalanceFromSchedule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/consumer/payment-intents/calculate-balance-from-schedule/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
    }),
    overrideExisting: false,
  });
export const { useCalculateBalanceFromScheduleMutation } = paymentsApi;
