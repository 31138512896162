import { createSlice } from "@reduxjs/toolkit";
import { DATE_FORMAT } from "common/constants";
import currency from "currency.js";
import moment from "moment-timezone";
import { paymentsApi } from "features/consumerPortalPaymentPage/paymentsAPI";

export const PAYMENT_VIEW_SCREEN_ENUMS = {
  SelectAccountsForPayment: "selectAccountsForPayment",
  SetupPaymentPlanConfiguration: "setupPaymentPlanConfiguration",
  SetupFullPayment: "setupFullPayment",
  SetupPartialPayment: "setupPartialPayment",
  // NOTE: These currently point to the same component, but we need the separation to properly keep track of previous and next steps.
  SelectPaymentMethod: "selectPaymentMethod",
  SelectPaymentOption: "selectPaymentOption",
  PaymentConfirmation: "paymentConfirmation",
};

export const initialState = {
  // Shared state fields
  // ------------------------------
  selectedAccounts: [],
  originalTotalAmount: null, // amount due across selected accounts
  partialPaymentAmount: null, // If a partial payment is flagged, this will be non-empty
  remainingAmountToAllocate: null,
  totalPaymentAmount: null, // The total payment amount. NOTE: This value is only available on and after the OneTimePaymentSchedule.
  isPaymentPlan: false, // defaults to one time payment, else it's a payment plan
  isNewPaymentMethod: true,
  startDate: moment().startOf("day").format(DATE_FORMAT), // Start of first payment. For one time payments, will be set to the payment date.

  // One Time Payments state fields
  // ------------------------------

  // full - Payment covers all selected account balances.
  // partial - Payment was made, but not the entire full amount.
  // settled - Payment covers all selected account balance, but payment is a settled amount (less than the full).
  paymentIntentType: "full", // [full, partial, settled]

  // Payment Plan state fields
  // ------------------------------
  numberOfPayments: 1, // Only applicable if it's a payment plan
  paymentFrequency: "monthly", // ["monthly", "weekly, biweekly", "bimonthly"]
  recurringAmount: null,
  downPayment: 0,
  balloonPayment: 0,

  // NOTE: The paymentsSchedule is a list of payments that will be made.
  // [payment.index]: {
  //   totalAmount: payment.amount,
  //   date: payment.date,
  // },
  paymentsSchedule: [],
  isIncludedWithLastPayment: true,

  balanceResponse: undefined,

  // Payment Method Screen
  paymentMethodOptions: undefined,
  currentView: PAYMENT_VIEW_SCREEN_ENUMS.SelectAccountsForPayment, // Defaults to first view in the Payments tab.
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    // Shared Reducers
    // ------------------------------
    setSelectedAccounts: (state, action) => {
      state.selectedAccounts = action.payload;
    },
    setPaymentIntentType: (state, action) => {
      state.paymentIntentType = action.payload;
    },

    // One Time Payment Reducers
    // ------------------------------
    setOriginalTotalAmount: (state, action) => {
      state.originalTotalAmount = action.payload;
    },
    setAsOneTimeFullPayment: (state, action) => {
      const { paymentIntentType } = action.payload;
      state.isPaymentPlan = false;
      state.paymentIntentType = paymentIntentType;
    },
    setPaymentTotalAmount: (state, action) => {
      state.totalPaymentAmount = action.payload;
    },
    setPaymentScheduleAmountChange: (state, action) => {
      //  TODO: Review this fully.
      // If a single payment amount is changed, need to update 3 things.
      // 1. The overall total remaining amount to distribute (across all payments)
      const { paymentIndex, newPaymentAmount, totalRemainingAmountToDistribute } = action.payload;
      // const individualPayment = [
      //   ...state.paymentPlan[`account-${individualPaymentIds.currentAccountId.toString()}`],
      // ][individualPaymentIds.singlePaymentIndex];
      const individualPayment = state.paymentsSchedule.find(
        (payment) => payment.index === paymentIndex,
      );
      individualPayment.amount = newPaymentAmount;

      // // 2. The individual payment's totalAmount being paid
      // // Update the amount remaining to allocate for this payment.
      // let totalForPayment = 0;
      // let index = 0;
      // state.paymentsSchedule.forEach((accountKey) => {
      //   totalForPayment += state.paymentsSchedule[index].amount;
      //   index += 1;
      // });
      // individualPayment.data.forEach((tableRow) => {
      //   totalForPayment += tableRow.total;
      // });
      // individualPayment.remainingToAllocate = individualPayment.totalPaymentAmount - totalForPayment;
      // 3. The individual payment's remaining amount to distribute
      state.remainingAmountToAllocate = totalRemainingAmountToDistribute;

      state.totalPaymentAmount = state.paymentsSchedule.reduce(
        (total, payment) => total.add(payment.amount),
        currency(0, { precision: 4 }),
      ).value;
    },
    setPaymentScheduleDateChange: (state, action) => {
      const { paymentIndex, date } = action.payload;
      const index = state.paymentsSchedule.findIndex((payment) => payment.index === paymentIndex);
      state.startDate = date;
      state.paymentsSchedule[index] = {
        ...state.paymentsSchedule[index],
        date,
      };
    },
    setOneTimePaymentsSchedule: (state, action) => {
      const {
        paymentIntentType,
        partialPaymentAmount,
        originalTotalAmount,
        totalPaymentAmount,
        paymentsSchedule,
      } = action.payload;
      state.paymentIntentType = paymentIntentType;
      if (paymentIntentType === "full") {
        state.partialPaymentAmount = null;
      } else {
        state.partialPaymentAmount = partialPaymentAmount;
      }
      state.isPaymentPlan = false;
      state.paymentsSchedule = paymentsSchedule;
      state.originalTotalAmount = originalTotalAmount;
      state.totalPaymentAmount = totalPaymentAmount;
      state.remainingAmountToAllocate = 0;
      // Clear out any values that are only used for Payment Plans.
      state.downPayment = null;
    },

    // Payment Plan Reducers
    // ------------------------------
    setupAsPaymentPlan: (state, action) => {
      const { originalTotalAmount } = action.payload;
      state.partialPaymentAmount = null;
      state.originalTotalAmount = originalTotalAmount;

      // Reset defaults
      state.numberOfPayments = 1;
      state.downPayment = null;
      state.startDate = moment().format(DATE_FORMAT);
      state.paymentFrequency = "monthly";
      state.recurringAmount = null;
      state.balloonPayment = 0;
      state.paymentsSchedule = [];
      state.totalPaymentAmount = state.paymentsSchedule.reduce(
        (total, payment) => total.add(payment.amount),
        currency(0, { precision: 4 }),
      ).value;
      state.isIncludedWithLastPayment = true;
    },
    setPaymentPlanConfiguration: (state, action) => {
      const {
        downPayment,
        recurringAmount,
        numberOfPayments,
        startDate,
        frequency,
        paymentsSchedule,
      } = action.payload;
      state.startDate = startDate;
      state.numberOfPayments = numberOfPayments;
      state.recurringAmount = recurringAmount;
      state.downPayment = downPayment;
      state.paymentFrequency = frequency;
      state.paymentsSchedule = paymentsSchedule;
      state.remainingAmountToAllocate = 0;
      state.totalPaymentAmount = paymentsSchedule.reduce(
        (total, payment) => total.add(payment.amount),
        currency(0, { precision: 4 }),
      ).value;
    },
    setPaymentFrequency: (state, action) => {
      state.paymentFrequency = action.payload;
    },
    setPaymentsSchedule: (state, action) => {
      state.paymentsSchedule = action.payload;
      state.remainingAmountToAllocate = 0;
      state.totalPaymentAmount = state.paymentsSchedule.reduce(
        (total, payment) => total.add(payment.amount),
        currency(0, { precision: 4 }),
      ).value;
    },
    setDownPayment: (state, action) => {
      const { downPayment, recurringAmount, balloonPayment, isIncludedWithLastPayment } =
        action.payload;
      state.downPayment = downPayment;
      state.recurringAmount = recurringAmount;
      state.balloonPayment = balloonPayment;
      state.isIncludedWithLastPayment = isIncludedWithLastPayment;
    },
    setNumberOfPaymentsChange: (state, action) => {
      const { numberOfPayments, recurringAmount, balloonPayment, isIncludedWithLastPayment } =
        action.payload;
      state.numberOfPayments = numberOfPayments;
      state.recurringAmount = recurringAmount;
      state.balloonPayment = balloonPayment;
      state.isIncludedWithLastPayment = isIncludedWithLastPayment;
    },
    setRecurringAmountChange: (state, action) => {
      const { recurringAmount, numberOfPayments, balloonPayment, isIncludedWithLastPayment } =
        action.payload;
      state.recurringAmount = recurringAmount;
      state.numberOfPayments = numberOfPayments;
      state.balloonPayment = balloonPayment;
      state.isIncludedWithLastPayment = isIncludedWithLastPayment;
    },
    setIsIncludedWithLastPaymentChange: (state, action) => {
      state.isIncludedWithLastPayment = action.payload;
    },
    setBalloonPayment: (state, action) => {
      state.balloonPayment = action.payload;
    },
    setRecurringAmount: (state, action) => {
      state.recurringAmount = action.payload;
    },
    setAsPaymentPlan: (state) => {
      state.isPaymentPlan = true;
    },
    setIsNewPaymentMethodOption: (state, action) => {
      state.isNewPaymentMethod = action.payload;
    },
    setCurrentView: (state, action) => {
      state.currentView = action.payload;
    },
    resetMakeAPayment: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      paymentsApi.endpoints.calculateBalanceFromSchedule.matchFulfilled,
      (state, { payload }) => {
        state.balanceResponse = payload.results;
      },
    );
  },
});

export const {
  setPaymentTotalAmount,
  setAsPaymentPlan,
  setAsOneTimeFullPayment,
  setPaymentScheduleAmountChange,
  setPaymentScheduleDateChange,
  setSelectedAccounts,
  setPaymentIntentType,
  setOneTimePaymentsSchedule,
  setupAsPaymentPlan,
  setPaymentPlanConfiguration,
  setOriginalTotalAmount,
  setDownPayment,
  setNumberOfPaymentsChange,
  setRecurringAmountChange,
  setBalloonPayment,
  setRecurringAmount,
  setPaymentFrequency,
  setIsNewPaymentMethodOption,
  resetMakeAPayment,
  setIsIncludedWithLastPaymentChange,
  setPaymentsSchedule,
  setCurrentView,
} = paymentsSlice.actions;

export const selectPaymentsSlice = (state) => state.payments;

export default paymentsSlice.reducer;
