import { DATE_FORMAT } from "common/constants";
import currency from "currency.js";
import moment from "moment-timezone";

export const getPaymentsScheduleForOneTimePayment = ({ accountIds, paymentAmount }) => [
  {
    index: 0,
    accountIds,
    title: "One Time Payment",
    date: moment().format(DATE_FORMAT),
    amount: paymentAmount,
  },
];

export const getNextDate = ({ currentDate, frequency }) => {
  if (frequency === "monthly") {
    currentDate = moment(currentDate).add(1, "M");
  } else if (frequency === "weekly") {
    currentDate = moment(currentDate).add(7, "days");
  } else if (frequency === "biweekly") {
    currentDate = moment(currentDate).add(14, "days");
  } else if (frequency === "bimonthly") {
    /* Iterating between 2 dates:
      some time in the first half of the month and in the second
      for example:
        4 payments
        StartDate of the plan: 5th of June.
        Payments
        June: 5th, 20th
        July 5th, 20th
      or:
        4 payments
        StartDate of the plan: 30th of January.
        Payments
        January: 30th
        February 15th, 28th
        March: 15th
    */
    const dayOftheMonth = moment(currentDate).date();
    if (dayOftheMonth > 15) {
      // rolling second half of the month to first
      const iteratingDate = dayOftheMonth - 15;
      currentDate = moment(currentDate).date(iteratingDate).add(1, "month");
    } else {
      // rolling first half of the month to second
      const iteratingDate = dayOftheMonth + 15;
      const daysInCurrentMonth = currentDate.daysInMonth();
      // handle February (month with <30 days)
      const validIteratingDate = Math.min(daysInCurrentMonth, iteratingDate);
      currentDate = moment(currentDate).date(validIteratingDate);
    }
  }
  return currentDate;
};

export const getPaymentsForPaymentPlanSchedule = ({
  accountIds,
  downPayment,
  recurringAmount,
  balloonPayment,
  numberOfPayments,
  startDate,
  frequency,
  isIncludedWithLastPayment,
}) => {
  const currentDay = moment();
  const paymentsArray = [];

  if (downPayment > 0) {
    // Create a single payment for the downPayment, separate from the payment plan.
    paymentsArray.push({
      index: -1,
      accountIds,
      title: "Down Payment",
      date: currentDay.format(DATE_FORMAT),
      amount: downPayment,
    });
  }

  let currentDate = startDate;
  for (let i = 0; i < numberOfPayments; i++) {
    const singleRecurringPayment = {
      index: i,
      accountIds,
      title: `Recurring Payment #${i + 1}`,
      date: moment(currentDate).format(DATE_FORMAT),
      amount: recurringAmount,
    };
    currentDate = getNextDate({ currentDate, frequency });

    // handle the last payment
    if (i === numberOfPayments - 1 && isIncludedWithLastPayment) {
      // The last payment should get the balloon payment.
      singleRecurringPayment.amount = currency(singleRecurringPayment.amount, {
        precision: 4,
      }).add(balloonPayment).value;
      // Mark that this is the last payment
      singleRecurringPayment.title = `Final (Recurring + Remaining) Payment`;
    }

    paymentsArray.push(singleRecurringPayment);

    if (i === numberOfPayments - 1 && !isIncludedWithLastPayment) {
      const baloonPayment = {
        index: i + 1,
        accountIds,
        title: `Remaining Amount`,
        date: currentDate.format(DATE_FORMAT),
        amount: balloonPayment,
      };
      paymentsArray.push(baloonPayment);
    }
  }
  console.log("All Payments: ", paymentsArray);
  return paymentsArray;
};

export const convertPaymentsScheduleToPaymentIntents = ({ paymentsSchedule }) => {
  const paymentIntentsByDateMap = {};
  paymentsSchedule.forEach((payment) => {
    const currentPaymentIntent = paymentIntentsByDateMap[payment.date] || {};
    currentPaymentIntent.scheduledDate = payment.date;
    currentPaymentIntent.totalAmount = payment.amount;
    currentPaymentIntent.accountIds = payment.accountIds;
    paymentIntentsByDateMap[payment.date] = currentPaymentIntent;
  });
  console.log("paymentIntentsByDateMap: ", paymentIntentsByDateMap);
  return paymentIntentsByDateMap;
};

export const getPaymentIntents = ({
  paymentMethodId,
  referenceNumber,
  paymentsSchedule,
  paymentIntentType,
  isPaymentPlan,
  promiseToPayDate,
  promiseToPayLastDate,
}) => {
  const paymentIntentsByDateMap = convertPaymentsScheduleToPaymentIntents({ paymentsSchedule });
  const byDateMapKeys = Object.keys(paymentIntentsByDateMap);
  const paymentIntents = byDateMapKeys.map((paymentDate, index) => {
    const isLast = index === byDateMapKeys.length - 1;
    return {
      ...paymentIntentsByDateMap[paymentDate],
      paymentMethodId,
      paymentIntentType: isLast ? paymentIntentType : "partial",
      isPaymentPlan,
      referenceNumber,
      promiseToPayDate,
      promiseToPayLastDate,
    };
  });
  console.log("paymentIntents: ", paymentIntents);
  return paymentIntents;
};
