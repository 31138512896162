import * as Sentry from "@sentry/react";
import { setupStore } from "common/redux/consumerPortalStore";
import { authSlice, loadAuthSlice } from "features/auth/authSlice";
import LocalProtectedRoute from "features/auth/components/localProtectedRoute";
import { ChangePassword } from "pages/consumerPortal/changePassword";
import { ForgotPassword } from "pages/consumerPortal/forgotPassword";
import { ContactUsPage } from "pages/consumerPortal/contactUs";
import { DashboardPage } from "pages/consumerPortal/dashboard";
import { DocumentsPage } from "pages/consumerPortal/documents";
import { NotFoundPage } from "pages/consumerPortal/errorPages";
import { Login } from "pages/consumerPortal/login";
import { Logout } from "pages/consumerPortal/logout";
import { MakePaymentPage } from "pages/consumerPortal/makePayment";
import { PaymentHistoryPage } from "pages/consumerPortal/paymentHistory";
import { PaymentMethodsPage } from "pages/consumerPortal/paymentMethods";
import { ProfilePage } from "pages/consumerPortal/profile";
import { Signup } from "pages/consumerPortal/signup";
import { UpcomingPaymentsPage } from "pages/consumerPortal/upcomingPayments";
import { GlobalErrorPage } from "pages/errorPages";
import config from "portal/envConfig";
import { ReduxStoreProvider, WhiteLabelProvider } from "providers";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";
import "./App.css";

const store = setupStore({
  [authSlice.name]: loadAuthSlice(),
});

function App() {
  const isDevMode = config.aktosEnv !== "prod";
  const aktosTitle = isDevMode ? `Consumer Portal (${config.aktosEnv})` : "Consumer Portal";
  useDocumentTitle(aktosTitle);

  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  const ProtectedRouteConsumer = <LocalProtectedRoute portalType="consumer" />;
  const router = sentryCreateBrowserRouter([
    {
      errorElement: <GlobalErrorPage />,
      children: [
        { path: "/login", Component: Login },
        { path: "/signup", Component: Signup },
        { path: "/forgot-password", Component: ForgotPassword },
        {
          path: "*",
          element: ProtectedRouteConsumer,
          errorElement: <GlobalErrorPage />,
          children: [
            { index: true, element: <Navigate to="/accounts" replace /> },
            { path: "accounts", Component: DashboardPage },
            { path: "payments/payment-history", Component: PaymentHistoryPage },
            { path: "payments/make-payment", Component: MakePaymentPage },
            { path: "payments/payment-methods", Component: PaymentMethodsPage },
            { path: "payments/upcoming-payments", Component: UpcomingPaymentsPage },
            { path: "documents", Component: DocumentsPage },
            { path: "contact", Component: ContactUsPage },
            { path: "profile", Component: ProfilePage },
            { path: "change-password", Component: ChangePassword },
            { path: "logout", Component: Logout },
            { path: "*", Component: NotFoundPage },
          ],
        },
      ],
    },
  ]);

  return (
    <ReduxStoreProvider store={store}>
      <WhiteLabelProvider
        defaultOverrides={{
          isError: true,
        }}
      >
        <RouterProvider router={router} />
      </WhiteLabelProvider>
    </ReduxStoreProvider>
  );
}

export default Sentry.withProfiler(App);
