import { Button, Form, Modal, Select, Space } from "antd";
import PhoneInput from "antd-phone-input"; // https://github.com/typesnippet/antd-phone-input
import { useCreateConsumerPortalPhoneNumberMutation } from "features/consumerPortalProfile/consumerPortalprofileAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-left: 0 !important;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    margin-bottom: 12px;
  }
`;
function AddPhoneNumberModal({ onClose }) {
  const [createPhoneNumber] = useCreateConsumerPortalPhoneNumberMutation();
  const { data: constants } = useFetchBackendConstantsQuery();

  const [form] = Form.useForm();

  const handleSave = async () => {
    const values = await form.validateFields();
    const result = await createPhoneNumber({
      ...values,
      number: `${values.number?.countryCode}${values.number?.areaCode}${values.number?.phoneNumber}`,
    });
    if ("data" in result) {
      onClose();
    }
  };

  const validator = (_, { valid }) => {
    // if (valid(true)) return Promise.resolve(); // strict validation
    if (valid()) return Promise.resolve(); // non-strict validation
    return Promise.reject(new Error("Invalid phone number"));
  };

  return (
    <StyledModal
      maskClosable={false}
      title="New Phone Number"
      onCancel={onClose}
      open
      footer={
        <Button type="primary" htmlType="submit" onClick={handleSave}>
          Add
        </Button>
      }
    >
      <Form form={form} layout="vertical" name="add_phone_number_form" onFinish={handleSave}>
        <Space.Compact>
          <Form.Item hasFeedback name="number" rules={[{ validator }]}>
            <PhoneInput enableSearch />
          </Form.Item>
          <Form.Item hasFeedback name="type">
            <StyledSelect
              allowClear
              popupMatchSelectWidth={false}
              style={{ width: 120, borderLeft: 0 }}
              placeholder="Type"
              options={constants?.phoneTypes.map(({ display, value }) => ({
                value,
                label: display,
              }))}
            />
          </Form.Item>
        </Space.Compact>
      </Form>
    </StyledModal>
  );
}

export default AddPhoneNumberModal;
