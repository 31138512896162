import { UserOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, message, Typography } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { resetStateAction } from "common/redux/resetStateAction";
import { useLazyFetchMeQuery, useLoginMutation } from "features/auth/authAPI";
import { selectIsAccessTokenStoredInRtk, updateTokens } from "features/auth/authSlice";
import { useWhiteLabel } from "providers/whiteLabelProvider";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 16px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
`;

const StyledDiv = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const StyledHeading = styled.h2`
  margin-top: 0;
  margin-bottom: 4px;
  text-align: center;
`;

const StyledCol = styled(Col)`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 48px;
  background: #fff;
  border-radius: 10px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 90px);
  position: absolute;
  background: #f7f7f7;
  padding-bottom: 24px;
`;

const BAD_REDIRECT_ROUTES = ["/login", "/signup", "/change-password"];

const DEFAULT_LOGIN_REDIRECT_SCREEN = "dashboard";

function ForgotPasswordForm() {
  const { isWhiteLabeled, displayName } = useWhiteLabel();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const [fetchMe] = useLazyFetchMeQuery();
  const isAccessTokenStoredInRtk = useSelector(selectIsAccessTokenStoredInRtk);

  // Reset state when user navigates to login page
  useEffect(() => {
    if (isAccessTokenStoredInRtk) {
      dispatch(resetStateAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToDestination = async () => {
    // Fetch the optional defaultLoginRedirectScreen field from /me
    // to see if there is a specific page a user should see
    // on login
    const { data: me } = await fetchMe();
    if ("error" in me) {
      form.setFields(formatError(me.error));
      setErrorMessage("Something went wrong");
    }

    // @ts-ignore
    const defaultLoginRedirectScreen = `/${
      me?.defaultLoginRedirectScreen || DEFAULT_LOGIN_REDIRECT_SCREEN
    }`;

    // Firstly, we look at returnTo object
    // Then, at defaultLoginRedirectScreen from fetchMe request
    // Lastly, we fall for this default value
    const returnTo =
      location.state?.returnTo && BAD_REDIRECT_ROUTES.indexOf(location.state.returnTo) === -1
        ? location.state.returnTo
        : defaultLoginRedirectScreen;

    navigate(returnTo);
  };

  const onFinish = async (values) => {
    const { email, password } = values;

    const result = await login({ email, password });
    if ("data" in result) {
      dispatch(updateTokens(result.data));
      await redirectToDestination();
      message.success("Logged in successfully.", 2);
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
      setErrorMessage("Invalid username or password");
    }
  };

  return (
    <StyledContainer>
      <StyledCol>
        <StyledHeading>Reset Password</StyledHeading>
        <Typography.Text type="secondary">
          Enter your login email for instructions to reset your password
        </Typography.Text>
        <StyledForm
          onFinish={onFinish}
          className="login-form"
          initialValues={{
            email: "",
            password: "",
          }}
          form={form}
        >
          <Form.Item
            name="email"
            hasFeedback
            rules={[
              { required: true, message: "Please enter email" },
              {
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" data-testid="email" />
          </Form.Item>

          <Form.Item>
            <StyledButton
              type="primary"
              htmlType="submit"
              className="login-form-button"
              data-testid="login-button"
              loading={isLoading}
            >
              Send Email
            </StyledButton>
          </Form.Item>
          {errorMessage && (
            <Form.Item>
              <Alert message={errorMessage} type="error" />
            </Form.Item>
          )}
        </StyledForm>
        <StyledDiv>
          <a href="/login">Back to Log In</a>
        </StyledDiv>
      </StyledCol>
    </StyledContainer>
  );
}

export default ForgotPasswordForm;
