import { ArrowLeftOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Flex, Form, Input, message, Row, Tooltip } from "antd";
import { DATE_FORMAT } from "common/constants";
import { formatCurrency, formatDate } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import currency from "currency.js";
import { useCreatePaymentIntentMutation } from "features/consumerPortalPaymentHistory/paymentIntentsAPI";
import AddPaymentMethodForm from "features/consumerPortalPaymentMethods/components/addPaymentMethodForm";
import AddPaymentMethodModal from "features/consumerPortalPaymentMethods/components/addPaymentMethodModal";
import { useCalculateBalanceFromScheduleMutation } from "features/consumerPortalPaymentPage/paymentsAPI";
import {
  PAYMENT_VIEW_SCREEN_ENUMS,
  selectPaymentsSlice,
  setCurrentView,
  setOneTimePaymentsSchedule,
  setPaymentScheduleDateChange,
} from "features/consumerPortalPaymentPage/paymentsSlice";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const StyledDiv = styled(Flex)`
  margin-top: 12px;
  flex-direction: row-reverse;
`;

const StyledValue = styled.span`
  overflow-wrap: anywhere;
  color: grey;
  font-size: 13px;
`;

const StyledPaymentSummaryCard = styled(Card)`
  min-width: 570px;
`;

const StyledLabel = styled.span`
  text-transform: capitalize;
  font-size: 13px;
  flex: 1;
`;

const StyledCard = styled(Card)`
  margin-bottom: 12px;
  width: 570px;
  margin-right: 12px;
  .ant-card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-card-head {
    border-bottom: none;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 0;
`;

const StyledPayButton = styled(Button)`
  height: 48px;
`;

function FullPayment() {
  const [form] = Form.useForm();
  const [processPayment, { isLoading: isPosting }] = useCreatePaymentIntentMutation();
  const dispatch = useDispatch();
  const paymentsSlice = useSelector(selectPaymentsSlice);
  const [isModalVisible, setModalVisible] = useState(false);
  const [
    calculateBalanceFromSchedule,
    { data, isLoading: isCalculateBalanceFromScheduleLoading, error },
  ] = useCalculateBalanceFromScheduleMutation();
  useEffect(() => {
    const scheduledDate = paymentsSlice.startDate;
    calculateBalanceFromSchedule({
      intents: [
        {
          scheduledDate,
          totalAmount: paymentsSlice.totalPaymentAmount,
          accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
        },
      ],
    })
      .unwrap()
      .then(() => {
        dispatch(
          setOneTimePaymentsSchedule({
            paymentIntentType: "full",
            originalTotalAmount: paymentsSlice.totalPaymentAmount,
            totalPaymentAmount: currency(paymentsSlice.originalTotalAmount).add(
              data?.summary?.[0]?.remainingBalanceTotal,
            ).value,
            paymentsSchedule: [
              {
                ...paymentsSlice.paymentsSchedule[0],
                date: paymentsSlice.paymentsSchedule[0].date,
              },
            ],
          }),
        );
      })
      .catch((_) => {});
    // NOTE: We don't want this to run every time the paymentsSchedule changes. Since React
    // requires us to list all dependencies used in the useEffect hook, we need to disable the eslint rule.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    calculateBalanceFromSchedule,
    paymentsSlice.startDate,
    // NOTE: We don't want this to run every time any of these change. Since React
    // requires us to list all dependencies used in the useEffect hook, we need to disable the eslint rule.
    // paymentsSlice.selectedAccounts,
    // paymentsSlice.paymentsSchedule,
    // paymentsSlice.totalPaymentAmount,
  ]);

  const onFinish = async ({
    scheduledDate,
    paymentMethodUuid,
    isNewPaymentMethod,
    newPaymentMethod,
  }) => {
    const intents = [
      {
        totalAmount: paymentsSlice.totalPaymentAmount,
        scheduledDate,
        paymentMethodUuid,
        accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
        paymentIntentType: "full",
        isPaymentPlan: false,
      },
    ];
    const newPaymentMethodFields = {};
    if (isNewPaymentMethod) {
      const { paymentMethod, ...newPaymentMethodValues } = newPaymentMethod;
      const [firstName, lastName] = (newPaymentMethodValues.name ?? "").split(" ");
      newPaymentMethodValues.firstName = firstName;
      newPaymentMethodValues.lastName = lastName;

      if (paymentMethod === "creditCard") {
        // post-transformation for credit card
        newPaymentMethodValues.cardExpMonth = moment(
          newPaymentMethodValues.cardExpDate,
          "MM/YY",
        ).format("MM");
        newPaymentMethodValues.cardExpYear = moment(
          newPaymentMethodValues.cardExpDate,
          "MM/YY",
        ).format("YYYY");
        newPaymentMethodFields.newCreditCard = newPaymentMethodValues;
      }
      if (paymentMethod === "bankAccount") {
        newPaymentMethodFields.newCreditCard = newPaymentMethodValues;
      }
    }
    const result = await processPayment({
      intents,
      ...newPaymentMethodFields,
      paymentIntentType: "full",
      executeIfReady: true,
    });
    if ("data" in result) {
      dispatch(setCurrentView(PAYMENT_VIEW_SCREEN_ENUMS.PaymentConfirmation));
    }
    if ("error" in result) {
      message.error("Failed to process payment");
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().startOf("day");
  };

  const initialValues = {
    totalPaymentAmount: paymentsSlice.totalPaymentAmount,
    scheduledDate: moment().format(DATE_FORMAT),
    isNewPaymentMethod: false,
    newPaymentMethod: {
      // paymentMethod: "creditCard",
    },
  };

  const changePaymentDate = (date) => {
    dispatch(
      setPaymentScheduleDateChange({
        paymentIndex: 0,
        date,
      }),
    );
  };

  const onPrevious = () => {
    dispatch(setCurrentView(PAYMENT_VIEW_SCREEN_ENUMS.SelectPaymentOption));
  };

  return (
    <>
      <StyledButton icon={<ArrowLeftOutlined />} type="link" onClick={onPrevious}>
        Previous: Choose Payment Option
      </StyledButton>
      <Form form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues}>
        <Flex>
          <Col>
            <StyledCard title="Payment Date">
              <Form.Item
                label="Scheduled Date"
                name="scheduledDate"
                rules={[{ required: true, message: "Please select the payment date" }]}
              >
                <AktDatePicker
                  onChange={changePaymentDate}
                  type="date"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </StyledCard>
            <StyledCard title="Contact">
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "Please enter your email" }]}
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
            </StyledCard>
            <StyledCard title="Payment Information">
              <AddPaymentMethodForm prefix="newPaymentMethod" />
            </StyledCard>
          </Col>
          <Col>
            <StyledPaymentSummaryCard
              loading={isCalculateBalanceFromScheduleLoading}
              title="Payment Summary"
            >
              {paymentsSlice.selectedAccounts?.map((selectedAccount) => {
                return (
                  <>
                    <Row align="middle">
                      <StyledLabel>Creditor Name</StyledLabel>
                      <StyledValue>{selectedAccount.currentCreditorName}</StyledValue>
                    </Row>
                    <Row align="middle">
                      <StyledLabel>Account Number</StyledLabel>
                      <StyledValue>{selectedAccount.externalId}</StyledValue>
                    </Row>
                    {/* <Row align="middle">
                      <StyledLabel>Current Balance</StyledLabel>
                      <StyledValue>{formatCurrency(selectedAccount.totalBalance)}</StyledValue>
                    </Row> */}
                  </>
                );
              })}
              <Divider />
              {paymentsSlice.paymentsSchedule?.map((paymentIntent) => {
                return (
                  <>
                    {/* <Row align="middle">
                      <StyledLabel>Payment Amount</StyledLabel>
                      <StyledValue>{formatCurrency(paymentIntent.amount)}</StyledValue>
                    </Row> */}
                    <Row align="middle">
                      <StyledLabel>Payment Date</StyledLabel>
                      <StyledValue>{paymentIntent.date}</StyledValue>
                    </Row>
                  </>
                );
              })}
              <Row align="middle">
                <StyledLabel>
                  Payment Amount{" "}
                  <Tooltip title="This amount may change based on the interest incurred from your selected payment date.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </StyledLabel>
                <StyledValue>{formatCurrency(paymentsSlice.totalPaymentAmount)}</StyledValue>
              </Row>
            </StyledPaymentSummaryCard>
            <StyledDiv>
              <StyledPayButton
                block
                size="large"
                type="primary"
                htmlType="submit"
                loading={isPosting}
              >
                {paymentsSlice.startDate === formatDate(moment().startOf("day"))
                  ? `Pay ${formatCurrency(paymentsSlice.totalPaymentAmount)}`
                  : `Schedule Payment (${formatCurrency(paymentsSlice.totalPaymentAmount)})`}
              </StyledPayButton>
            </StyledDiv>
          </Col>
        </Flex>
      </Form>

      {isModalVisible && (
        <AddPaymentMethodModal
          onCancel={() => setModalVisible(false)}
          onOk={() => {
            setModalVisible(false);
            form.setFieldsValue({ isNewPaymentMethod: false });
          }}
          open={isModalVisible}
        />
      )}
    </>
  );
}

export default FullPayment;
