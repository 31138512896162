import { Layout } from "antd";
import Header from "layouts/consumerPortal/header";
import AppMenu from "layouts/consumerPortal/menu";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

const StyledContent = styled(Layout.Content)`
  padding: 8px 24px;
  height: calc(100vh - 64px);
  margin-top: 64px;
  overflow: auto;
`;

function PageLayout() {
  return (
    <Layout>
      <Header />
      <Layout>
        <AppMenu />
        <StyledContent>
          <Outlet />
        </StyledContent>
      </Layout>
    </Layout>
  );
}

export default PageLayout;
