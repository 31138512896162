import { Table } from "antd";
import { DATE_FORMAT } from "common/constants";
import currency from "currency.js";
import { useFetchPaymentIntentsQuery } from "features/consumerPortalPaymentHistory/paymentIntentsAPI";
import moment from "moment-timezone";

export default function ConsumerPortalUpcomingPayments() {
  const { data: accounts, isLoading: isAccountsLoading } = useFetchPaymentIntentsQuery({
    scheduledDateStart: moment().add("day", 1).format(DATE_FORMAT),
  });

  const columns = [
    {
      title: "Scheduled Date",
      dataIndex: "scheduledDate",
      key: "scheduledDate",
      render: (_, record) =>
        record.scheduledDate && moment(record.scheduledDate).format(DATE_FORMAT),
    },
    {
      title: "Account ID(s)",
      dataIndex: "accountExternalIds",
      key: "accountExternalIds",
      render: (_, record) => (record.accountExternalIds ?? []).join(", "),
    },
    {
      title: "Payment Method",
      dataIndex: "method",
      key: "method",
      render: (_, record) => {
        if (record?.paymentMethod?.type === "process_card") {
          return `Credit Card ****${record?.paymentMethod.cardLast4Digits}`;
        }
        if (record?.paymentMethod?.type === "process_bank_account") {
          return `Bank Account ****${record?.paymentMethod.bankAccountLast4Digits}`;
        }

        return "-";
      },
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (_, record) => currency(record.totalAmount, { precision: 2 }).format(),
    },
    // {
    //   title: "",
    //   dataIndex: "action",
    //   key: "action",
    //   render: () => (
    //     <Space size="middle">
    //       <Button type="link">Cancel</Button>
    //     </Space>
    //   ),
    // },
  ];

  return (
    <>
      <h3>Upcoming Payments</h3>
      <Table
        scroll={{ x: "max-content" }}
        loading={isAccountsLoading}
        dataSource={accounts}
        columns={columns}
      />
    </>
  );
}
