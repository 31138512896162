import api, { transformRequest, transformResponse } from "services/api";

export const profileApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "ConsumerPortalAddresses",
      "ConsumerPortalPhoneNumbers",
      "ConsumerPortalEmailAddresses",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getConsumerPortalAddresses: build.query({
        query: () => `/api/v1/consumer/addresses/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: [{ type: "ConsumerPortalAddresses", id: "LIST" }],
      }),
      createConsumerPortalAddress: build.mutation({
        query: (payload) => ({
          url: `/api/v1/consumer/addresses/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "ConsumerPortalAddresses", id: "LIST" }],
      }),
      getConsumerPortalPhoneNumbers: build.query({
        query: () => `/api/v1/consumer/phone-numbers/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: [{ type: "ConsumerPortalPhoneNumbers", id: "LIST" }],
      }),
      createConsumerPortalPhoneNumber: build.mutation({
        query: (payload) => ({
          url: `/api/v1/consumer/phone-numbers/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "ConsumerPortalPhoneNumbers", id: "LIST" }],
      }),
      getConsumerPortalEmailAddresses: build.query({
        query: () => `/api/v1/consumer/emails/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: [{ type: "ConsumerPortalEmailAddresses", id: "LIST" }],
      }),
      createConsumerPortalEmailAddress: build.mutation({
        query: (payload) => ({
          url: `/api/v1/consumer/emails/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "ConsumerPortalEmailAddresses", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useCreateConsumerPortalAddressMutation,
  useGetConsumerPortalAddressesQuery,
  useCreateConsumerPortalPhoneNumberMutation,
  useGetConsumerPortalPhoneNumbersQuery,
  useCreateConsumerPortalEmailAddressMutation,
  useGetConsumerPortalEmailAddressesQuery,
} = profileApi;
