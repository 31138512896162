import api, { transformMultipart, transformResponse } from "services/api";

export const documentsApi = api
  .enhanceEndpoints({ addTagTypes: ["ConsumerPortalAccounts"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getConsumerPortalAccounts: build.query({
        query: () => `/api/v1/consumer/accounts/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ uuid }) => ({ type: "ConsumerPortalAccounts", uuid })),
                { type: "ConsumerPortalAccounts", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'ConsumerPortalAccounts', id: 'LIST' }` is invalidated
              [{ type: "ConsumerPortalAccounts", id: "LIST" }],
      }),
      postConsumerPortalAccounts: build.mutation({
        query: ({ file, ...values }) => ({
          url: `/api/v1/consumer/accounts/create/`,
          method: "POST",
          body: transformMultipart(values, file),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all Documents-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "ConsumerPortalAccounts", id: "LIST" }],
      }),
    }),
  });

export const {
  useGetConsumerPortalAccountsQuery,
  useLazyGetConsumerPortalAccountsQuery,
  usePostConsumerPortalAccountsMutation,
} = documentsApi;
