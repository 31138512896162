import api, { transformRequest, transformResponse } from "services/api";

export const paymentIntentsApi = api
  .enhanceEndpoints({
    addTagTypes: ["ConsumerPortalPaymentHistory", "ConsumerPortalPaymentIntents"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchPaymentIntents: build.query({
        query: () => ({
          url: `/api/v1/consumer/payment-intents/`,
          method: "POST",
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: [{ type: "ConsumerPortalPaymentIntents", id: "LIST" }],
      }),
      fetchPaymentHistory: build.query({
        query: () => ({
          url: `/api/v1/consumer/payment-intents/payment-history-report/`,
          method: "POST",
          headers: { "aktos-suppress-error": "true" },
          body: {},
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: [{ type: "ConsumerPortalPaymentHistory", id: "LIST" }],
      }),
      createPaymentIntent: build.mutation({
        query: (body) => ({
          url: `/api/v1/consumer/payment-intents/create/`,
          method: "POST",
          body: transformRequest(body),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "ConsumerPortalPaymentIntents", id: "LIST" }],
      }),
    }),

    overrideExisting: false,
  });

export const {
  useFetchPaymentHistoryQuery,
  useCreatePaymentIntentMutation,
  useFetchPaymentIntentsQuery,
} = paymentIntentsApi;
