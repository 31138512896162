import { HomeFilled, MailFilled, PhoneFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Flex, List, Row, Space, Tag } from "antd";
import { renderAddress, renderPhoneNumber } from "common/utils";
import { useFetchMeQuery } from "features/auth/authAPI";
import AddAddressModal from "features/consumerPortalProfile/components/addAddressModal";
import AddEmailAddressModal from "features/consumerPortalProfile/components/addEmailAddressModal";
import AddPhoneNumberModal from "features/consumerPortalProfile/components/addPhoneNumberModal";
import {
  useGetConsumerPortalAddressesQuery,
  useGetConsumerPortalEmailAddressesQuery,
  useGetConsumerPortalPhoneNumbersQuery,
} from "features/consumerPortalProfile/consumerPortalprofileAPI";
import { useFetchConsumerBackendConstantsQuery } from "features/home/consumerPortal/homeAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledAdditionalHeader = styled.h4`
  margin-bottom: 4px;
`;

function ConsumerPortalProfile() {
  const [isPhoneModalVisible, setIsPhoneModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false);

  const { data: phoneNumbers = [], isLoading: isPhoneNumbersLoading } =
    useGetConsumerPortalPhoneNumbersQuery();
  const { data: emailAddresses = [], isLoading: isEmailAddressesLoading } =
    useGetConsumerPortalEmailAddressesQuery();
  const { data: addresses = [], isLoading: isAddressesLoading } =
    useGetConsumerPortalAddressesQuery();
  const { data: constants } = useFetchConsumerBackendConstantsQuery();

  const { data: me } = useFetchMeQuery();

  return (
    <>
      <Flex vertical>
        <h2
          style={{
            marginBottom: 4,
          }}
        >
          Profile
        </h2>
        <div style={{ fontSize: 12, marginBottom: 20, fontWeight: "normal", color: "gray" }}>
          View and update your phone numbers, email, and addresses.
        </div>
        <Card
          title={
            <Flex
              style={{
                marginBottom: 12,
              }}
              align="center"
              justify="space-between"
            >
              <div>
                <Flex align="center" style={{ marginBottom: -24 }}>
                  <PhoneFilled style={{ marginRight: 8 }} />
                  <h4>Phone Numbers</h4>
                </Flex>
                <span style={{ fontSize: 12, fontWeight: "normal", color: "grey" }}>
                  Make sure to keep your primary phone number up-to-date.
                </span>
              </div>
              <Button
                style={{ marginTop: 12 }}
                shape="round"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsPhoneModalVisible(true)}
              >
                Add Phone Number
              </Button>
            </Flex>
          }
          style={{ marginBottom: 12 }}
        >
          <List
            dataSource={[...phoneNumbers].sort((a, b) => (a.isPrimary ? -1 : 1))}
            renderItem={(item, index) => (
              <Col>
                {index === 1 && <StyledAdditionalHeader>Additional:</StyledAdditionalHeader>}
                <Row>
                  <Space>
                    {renderPhoneNumber(item)}
                    {item.isPrimary && <Tag>PRIMARY ({item.type.toUpperCase()})</Tag>}
                  </Space>
                </Row>
              </Col>
            )}
            loading={isPhoneNumbersLoading}
          />
        </Card>
        <Card
          title={
            <Flex
              style={{
                marginBottom: 12,
              }}
              align="center"
              justify="space-between"
            >
              <div>
                <Flex align="center" style={{ marginBottom: -24 }}>
                  <MailFilled style={{ marginRight: 8 }} />
                  <h4>Email Addresses</h4>
                </Flex>
                <span style={{ fontSize: 12, fontWeight: "normal", color: "grey" }}>
                  Make sure to keep your primary email address up-to-date.
                </span>
              </div>
              <Button
                style={{ marginTop: 12 }}
                shape="round"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsEmailModalVisible(true)}
              >
                Add Email
              </Button>
            </Flex>
          }
          style={{ marginBottom: 12 }}
        >
          <List
            dataSource={[...emailAddresses].sort((a, b) => (a.isPrimary ? -1 : 1))}
            renderItem={(item, index) => (
              <Col>
                {index === 1 && <StyledAdditionalHeader>Additional:</StyledAdditionalHeader>}
                <Row>
                  <Space>
                    {item.isPrimary ? <i>{item.address}</i> : item.address}
                    {item.isPrimary && <Tag>PRIMARY ({item.type.toUpperCase()})</Tag>}
                  </Space>
                </Row>
              </Col>
            )}
            loading={isEmailAddressesLoading}
          />
        </Card>
        <Card
          title={
            <Flex
              style={{
                marginBottom: 12,
              }}
              align="center"
              justify="space-between"
            >
              <div>
                <Flex align="center" style={{ marginBottom: -24 }}>
                  <HomeFilled style={{ marginRight: 8 }} />
                  <h4>Addresses</h4>
                </Flex>
                <span style={{ fontSize: 12, fontWeight: "normal", color: "grey" }}>
                  Make sure to keep your primary address up-to-date.
                </span>
              </div>
              <Button
                style={{ marginTop: 12 }}
                shape="round"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsAddressModalVisible(true)}
              >
                Add Address
              </Button>
            </Flex>
          }
          style={{ marginBottom: 12 }}
        >
          <List
            dataSource={[...addresses].sort((a, b) => (a.isPrimary ? -1 : 1))}
            renderItem={(item, index) => (
              <Col>
                {index === 1 && <StyledAdditionalHeader>Additional:</StyledAdditionalHeader>}
                <Row>
                  <Space>
                    {renderAddress(item, constants?.states)}
                    {item.isPrimary && <Tag>PRIMARY ({item.type.toUpperCase()})</Tag>}
                  </Space>
                </Row>
              </Col>
            )}
            loading={isAddressesLoading}
          />
        </Card>
      </Flex>
      {isAddressModalVisible && <AddAddressModal onClose={() => setIsAddressModalVisible(false)} />}
      {isEmailModalVisible && (
        <AddEmailAddressModal onClose={() => setIsEmailModalVisible(false)} />
      )}
      {isPhoneModalVisible && <AddPhoneNumberModal onClose={() => setIsPhoneModalVisible(false)} />}
    </>
  );
}

export default ConsumerPortalProfile;
