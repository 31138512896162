import { Button, Result } from "antd";

function PaymentConfirmation() {
  return (
    <Result
      status="success"
      title="Great, we all done!"
      extra={<Button type="default">Go to the list of your accounts!</Button>}
    />
  );
}

export default PaymentConfirmation;
