import { Layout } from "antd";
import CompanyLogo from "assets/aktos-logo-366x340-nowhitespace.png";
import { useWhiteLabel } from "providers/whiteLabelProvider";
import styled from "styled-components";

const StyledImg = styled.img``;

const StyledHeader = styled(Layout.Header)`
  min-width: 470px;
  z-index: 10;
  border-bottom: 1px solid #d9d9d9;
  background: #fff;
  @media only screen and (max-width: 470px) {
    display: none;
  }
  &&& {
    padding-inline: 0;
  }
`;

function LoginPageLayout({ children }) {
  const { isWhiteLabeled, logo } = useWhiteLabel();

  return (
    <>
      <StyledHeader>
        <StyledImg alt="Logo" src={isWhiteLabeled ? logo : CompanyLogo} height={64} />
      </StyledHeader>
      {children}
    </>
  );
}

export default LoginPageLayout;
