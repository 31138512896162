import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { queryErrorLogger } from "common/redux/middleware/queryErrorLogger";
import { unauthenticatedMiddleware } from "common/redux/middleware/unauthenticated";
import { RESET_STATE_ACTION_TYPE } from "common/redux/resetStateAction";
import authReducer from "features/auth/authSlice";
import paymentsReducer from "features/consumerPortalPaymentPage/paymentsSlice";
import api from "services/api";

const reducers = combineReducers({
  auth: authReducer,
  // appMenu: appMenuReducer, // We will change this to consumer portal needs
  payments: paymentsReducer,
  // rtk-query part
  [api.reducerPath]: api.reducer,
});

export const rootReducer = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    // remove auth key
    localStorage.removeItem("rtk:auth");

    // then reset the state
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([unauthenticatedMiddleware, queryErrorLogger, api.middleware]),
    preloadedState,
  });
